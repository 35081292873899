import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white, orange } from 'src/styles/colors'

export const CashbackSection = styled.section`

  a {
    color: ${orange.extra};
    background-color: ${white};
    &:hover {
      background-color: ${white};
      color: ${orange}!important;
    }
    @media ${device.desktopLG} {
      min-width: 412px;
    }

    @media ${device.desktopXL} {
      min-width: 457px;
    }
  }
  position: relative;
  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cartao-credito-loop/image.webp');
    background-repeat: no-repeat;
    background-position: calc(100vw - 350px) 0;
    background-size: contain;
  }
  @media ${device.desktopLG} {
    background-position: calc(100vw - 450px) 0;
    min-height: 541px;
  }
  @media ${device.desktopXL} {
    background-position: right;
    min-height: 544px;
  }

  svg {
    path {
      stroke-width: 3;
    }
    @media ${device.tablet} {
      width: 31px;
      height: 31px;
    }
    @media ${device.desktopLG} {
      width: 47px;
      height: 47px;
    }
  }
`
