import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section``

export const ButtonDigitalCard = styled.div`

  a {
    text-transform: none;

    max-width: 312px;
    min-height: 38px;

    @media ${device.tablet} {
      max-width: 336px;
      min-height: 48px;
    }
    @media ${device.desktopLG} {
      max-width: 456px;
      min-height: 48px;
      float: left;
    }
    @media ${device.desktopXL} {
      max-width: 457px;
      min-height: 48px;
      float: left;
    }
  }
`

export const LogosPay = styled.div`
  min-height: 330px;

  .logos {
    min-height: 83px;
  }

  .content-text {
    @media ${device.tablet} {
      min-height: 298px;
    }
    @media ${device.desktopLG} {
      min-height: 413px;
    }
    @media ${device.desktopXL} {
      min-height: 379px;
    }
  }

  @media ${device.desktopLG} {
    min-height: 300px;
  }
  @media ${device.desktopXL} {
    min-height: 330px;
  }

  .gatsby-image-wrapper {
    max-width: 175px;
  }

  .more-details {
    bottom: 10px;
  }
`
export const ApplePay = styled.div`
  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  .react-multiple-carousel__arrow--right {
    right: 0;
  }
`
