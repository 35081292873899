import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const CompleteDigitalAccount = styled.div`
  .card-carousel {
    min-height: 92px;
    @media ${device.tablet} {
      min-height: 78px;
    }
  }
`
export const Button = styled.div`
  button, a {
    text-transform: none;
    @media ${device.tablet} {
      max-width: 100%;
      margin: 0 auto;
    }
    @media ${device.desktopXL} {
      max-width: 88% !important;
    }
  }
`
