import React from 'react'

// Components
import ViewMore from 'src/components/ViewMore'
import Faq from 'src/components/Faq/index'

import { FAQSection } from './style'

// PageContext
import pageContext from './../../pageContext.json'

const FaqSection = () => {
  return (
    <FAQSection id='perguntas-frequentes' className='bg-gray py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='text-grayscale--400 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3'>Dúvidas frequentes sobre os cartões Inter</h3>
          </div>
          <ViewMore classwrapper='d-md-flex flex-wrap w-100' color='gray' height={780}>
            <Faq id='cartoes' className='px-0 pl-lg-3 px-xl-3' answerData={pageContext.structuredData.faq} searchBg='#fff' />
          </ViewMore>
        </div>
      </div>
    </FAQSection>
  )
}

export default FaqSection
