import React from 'react'
import Img from 'gatsby-image'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useWidth from 'src/hooks/window/useWidth'
import ApllePayContentJSON from './../../assets/data/ApllePayContent.json'
import { Section, ButtonDigitalCard, LogosPay, ApplePay } from './style'
import usePageQuery from './../../pageQuery'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

type ItemProps = {
  logo: string;
  title: string;
  description: string;
  url: string;
}

const WIDTH_MD = 768

const CarteirasDigitais = () => {
  const windowWidth = useWidth(300)
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      id='carteiras-digitais'
      className='d-md-flex position-relative align-items-center bg-grayscale--100 pt-3'
    >
      <div className='container py-5'>
        <div className='row align-items-center'>
          <div className='col-md-6 col-lg-7 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 my-4 mt-lg-0 text-grayscale--500'>
              <span className='d-lg-block'>Carteiras digitais:</span>
              <span className='d-lg-inline d-xl-block'> facilidade na palma </span>
              da sua <span className='d-lg-block d-xl-inline'>mão</span>
            </h2>
            <p className='fs-16 lh-18 fs-xl-18 lh-xl-22 mb-4 text-grayscale--400'>
              <span className='d-lg-block d-xl-inline'>Pague usando apenas celular, relógio ou pulseira. O cartão </span>
              <span className='d-lg-block d-xl-inline'>
                Inter é compatível com as principais carteiras digitais do
              </span>
              <span className='d-lg-block d-xl-inline'> mercado.</span>
            </p>
            <ButtonDigitalCard className='col-12 text-center text-md-left px-0 pt-lg-3'>
              <Link
                to='/pra-voce/cartoes/carteiras-digitais/'
                target='_blank'
                rel='noreferrer'
                className={
                  `btn btn-orange--extra ${windowWidth < WIDTH_MD ? '' : 'btn--lg'} fs-14 fw-700 text-none rounded-3 ml-lg-0`
                }
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    section_name: 'Carteiras digitais: facilidade na palma da sua mão',
                    element_action: 'click button',
                    element_name: 'Conheça as carteiras digitais',
                    redirect_url: 'https://inter.co/pra-voce/cartoes/carteiras-digitais/',
                  })
                }}
              >
                Conheça as carteiras digitais
              </Link>
            </ButtonDigitalCard>
          </div>
          <div className='d-none d-md-block col-md-6 col-lg-5 offset-lg-0 offset-xl-2 order-lg-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-app-1440/image.webp'
              altDescription='Pessoa fazendo pagamento utilizando o cartão Inter na carteira digital'
              arrayNumbers={[ 300, 350, 350, 400 ]}
            />
          </div>
        </div>

        <div className='row'>
          <ApplePay className='col-12 d-md-none'>
            <DefaultCarousel
              sm={{
                    items: 1,
                    partialVisibilityGutter: 30,
              }}
              md={{
                    items: 4,
              }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
              removeArrowOnDeviceType={[ 'desktop' ]}
            >
              {
                ApllePayContentJSON.map((item: ItemProps) => (
                  <LogosPay key={item.title} className='rounded-3 pt-xl-5 mr-2 p-3 mr-md-3 bg-white d-flex flex-column justify-content-between'>
                    <div>
                      <div className='mb-4 mb-md-4 logos d-flex align-items-center'>
                        <Img fluid={data[item.logo].fluid} alt={item.logo} />
                      </div>
                      <h3 className='fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</h3>
                      <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400'>{item.description}</p>
                    </div>
                    <div>
                      <p className='fs-16 lh-20 text-orange--light fw-600 more-details'>
                        <a
                          href={item.url}
                          target='_blank'
                          rel='noreferrer'
                          title={`Mais detalhes sobre ${item.title}`}
                          onClick={() => {
                            sendDatalayerEvent({
                              section: 'dobra_06',
                              section_name: `Mais detalhes + ${item.title}`,
                              element_action: 'click button',
                              element_name: 'Solicitar cartão de crédito gratuito',
                              redirect_url: item.url,
                            })
                          }}
                        >
                          Mais detalhes
                        </a>
                      </p>
                    </div>
                  </LogosPay>
                ))
              }
            </DefaultCarousel>
          </ApplePay>
        </div>
        <div className='row mt-5'>
          {
            ApllePayContentJSON.map((item: ItemProps) => (
              <LogosPay key={item.title} className='col-md-6 col-lg-3 d-none d-md-inline mb-3'>
                <div className='rounded-3 px-4 px-md-3 py-4 content-text bg-white d-flex flex-column justify-content-between'>
                  <div>
                    <div className='mb-4 mb-md-4 logos d-flex align-items-center'>
                      <Img fluid={data[item.logo].fluid} alt={item.title} />
                    </div>
                    <h3 className='fs-16 lh-20 text-grayscale--500 fw-600'>{item.title}</h3>
                    <p className='fs-14 fs-lg-18 lh-17 lh-lg-22 text-grayscale--400'>{item.description}</p>
                  </div>
                  <div>
                    <p className='fs-16 lh-20 text-orange--light fw-600 d-none d-md-block more-details'>
                      <a
                        href={item.url}
                        target='_blank'
                        rel='noreferrer'
                        title={`Mais detalhes sobre ${item.title}`}
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_06',
                            section_name: `Mais detalhes + ${item.title}`,
                            element_action: 'click button',
                            element_name: 'Solicitar cartão de crédito gratuito',
                            redirect_url: item.url,
                          })
                        }}
                      >
                        Mais detalhes
                      </a>
                    </p>
                  </div>
                </div>
              </LogosPay>
            ))
          }
        </div>
      </div>
    </Section>
  )
}

export default CarteirasDigitais
