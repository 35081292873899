import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Button = styled.div`
  button, a {
    text-transform: none;

    @media ${device.tablet} {
      max-width: 456px;
    }
    @media ${device.desktopLG} {
      max-width: 456px;
    }

    @media ${device.desktopXL} {
      max-width: 583px;
    }
  }
`
