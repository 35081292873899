import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      cartaoInterXL: imageSharp(fluid: {originalName: { regex: "/use-cartao-inter-xl/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoInterLG: imageSharp(fluid: {originalName: { regex: "/use-cartao-inter-lg/" }}) {
        fluid(maxWidth: 376, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      appLimiteCartao: imageSharp(fluid: {originalName: { regex: "/app-limite-cartao/" }}) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      carteiraDigital: imageSharp(fluid: {originalName: { regex: "/carteira-digital/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundCashbackMobile: imageSharp(fluid: {originalName: { regex: "/inter-loop-programa-de-pontos-mobile/" }}) {
        fluid(maxWidth: 282, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundCashbackMD: imageSharp(fluid: {originalName: { regex: "/inter-loop-ao-pagar-fatura-bg-md/" }}) {
        fluid(maxWidth: 386, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundCashbackLG: imageSharp(fluid: {originalName: { regex: "/inter-loop-ao-pagar-fatura-bg-lg/" }}) {
        fluid(maxWidth: 540, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundCashbackXL: imageSharp(fluid: {originalName: { regex: "/inter-loop-ao-pagar-fatura-bg-xl/" }}) {
        fluid(maxWidth: 619, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoVirtual: imageSharp(fluid: {originalName: { regex: "/cartao-virtual-lp-cartoes/" }}) {
        fluid(maxWidth: 492, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      goldCard: imageSharp(fluid: {originalName: { regex: "/cartao-inter-gold/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      platinumCard: imageSharp(fluid: {originalName: { regex: "/cartao-inter-platinum/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      BeneficiosBlackCard: imageSharp(fluid: {originalName: { regex: "/beneficios-cartao-inter-black/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      winCard: imageSharp(fluid: {originalName: { regex: "/cartao-inter-win/" }}) {
        fluid(maxWidth: 534, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      apllePay: imageSharp(fluid: {originalName: { regex: "/logo-aplle-pay/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      googlePay: imageSharp(fluid: {originalName: { regex: "/logo-google-pay/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      facebookPay: imageSharp(fluid: {originalName: { regex: "/logo-facebook-pay/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      whatsAppPay: imageSharp(fluid: {originalName: { regex: "/logo-whatsapp-pay-cartoes/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      pay: imageSharp(fluid: {originalName: { regex: "/logo-samsung-pay-cartoes/" }}) {
        fluid(maxWidth: 172, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoInternacional: imageSharp(fluid: {originalName: { regex: "/cartao-internacional/" }}) {
        fluid(maxWidth: 508, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
