import React, { useState, useEffect } from 'react'

// Components
import useWidth from 'src/hooks/window/useWidth'
import { getParameterByName } from 'src/shared/helpers'

import { CreditOnTime } from './style'
// utils
import { alphaCode } from 'src/utils/alpha-code-utms'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'

type modalProps = {
  setIsModalOpen: Function;
  setDataLayer: Function;
}

const WIDTH_MD = 768

const CreditoNaHora = ({ setIsModalOpen, setDataLayer }: modalProps) => {
  const [ utmSource, setUtmSource ] = useState<string>('9io9bkj')
  const [ utmCampaign, setUtmCampaign ] = useState<string>('site_contadigital_aquisicao')

  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Aumente seu limite de crédito na hora',
    element_action: 'click button',
    element_name: 'Solicitar cartão de crédito gratuito',
  }

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '') as string || '9io9bkj')
    setUtmCampaign(getParameterByName('utm_campaign', '') as string || 'site_contadigital_aquisicao')
  }, [])

  return (
    <CreditOnTime id='limite-de-credito'>
      <div className='container pt-md-4 pb-md-0'>
        <div className='row align-items-center'>

          <div className='d-none d-md-block col-md-6 col-lg-5 col-xl-7'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/mockup-cartoes/image.webp'
              altDescription='Tela de celular com o CDB Mais Limite'
              arrayNumbers={[ 300, 350, 350, 400 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 offset-xl-0 col-xl-5'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4'>
              Aumente seu limite de crédito na hora
            </h3>

            <div className='col-12 d-md-none'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/mockup-cartoes/image.webp'
                altDescription='Tela de celular com o CDB Mais Limite'
                arrayNumbers={[ 300, 350, 350, 400 ]}
              />
            </div>

            <p className='fs-16 lh-18 fs-xl-18 lh-xl-22 text-gray--800 mt-4'>
              Todo valor investido no <span className='text-orange--extra fw-600'>Poupança Mais Limite</span> ou no <span className='text-orange--extra fw-600'>CDB Mais Limite</span> vira limite no seu cartão.
            </p>

            <p className='fs-16 lh-18 fs-xl-18 lh-xl-22 text-gray--800'>
              Solicite aumento de limite direto no Super App. É rápido e fácil!
            </p>

            <div className='mt-4 mt-lg-4 mb-4'>
              {windowWidth < WIDTH_MD
                 ? <a
                     href={`https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-cartao-mobile&adj_label=aquisicao&adj_fallback=https%3A%2F%2Finter.co%2F&adj_redirect_macos=https%3A%2F%2Finter.co%2F&adj_redirect_ios=https%3A%2F%2Fgointer.app%2F`}
                     target='_blank'
                     rel='noreferrer'
                     className='btn btn-orange--extra fs-14 fw-700 rounded-3'
                     onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_04',
                          section_name: 'Aumente seu limite de crédito na hora',
                          element_action: 'click button',
                          element_name: 'Solicitar cartão de crédito gratuito',
                          redirect_url: `https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-cartao-mobile&adj_label=aquisicao&adj_fallback=https%3A%2F%2Finter.co%2F&adj_redirect_macos=https%3A%2F%2Finter.co%2F&adj_redirect_ios=https%3A%2F%2Fgointer.app%2F`,
                        })
                     }}
                   >
                   Solicitar cartão de crédito gratuito
                   </a>
                 : <button
                     onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent(dataLayer)
                      setDataLayer(dataLayer)
                     }}
                     className='btn btn-orange--extra btn--lg fs-14 fw-700 rounded-3'
                   >
                   Solicitar cartão de crédito gratuito
                   </button>
              }
            </div>
          </div>
        </div>
      </div>
    </CreditOnTime>
  )
}

export default CreditoNaHora
