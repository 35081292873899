import React, { useLayoutEffect, useState, useRef, ReactNode, RefObject } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import useWidth from 'src/hooks/window/useWidth'

import { OverrideCss } from './style'

type ItemsProps = {
  verticalItems?: number;
  horizontalItems?: number;
  slidesToSlide?: number;
  partialVisibilityGutter?: number;
}

type VerticalCarouselProps = {
  children: ReactNode[];
  sm: ItemsProps;
  md: ItemsProps;
  lg: ItemsProps;
  xl: ItemsProps;
  removeArrowOnDeviceType?: string[];
  containerClass?: string;
  customDots?: ReactNode;
}

const WIDTH_MD = 768
const WIDTH_LG = 992
const WIDTH_XL = 1200

const VerticalCarousel = ({ children, sm, md, lg, xl, removeArrowOnDeviceType, containerClass, customDots}: VerticalCarouselProps) => {
  const CarouselRef = useRef<Carousel>()
  const windowWidth = useWidth(300)
  const [ itemSlider, setItemSlider ] = useState([])
  const [ breakpoint, setBreakpoint ] = useState(sm)
  const CustomDots: ReactNode | null = customDots
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: xl.horizontalItems,
      slidesToSlide: xl.slidesToSlide,
      partialVisibilityGutter: xl.partialVisibilityGutter,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: lg.horizontalItems,
      partialVisibilityGutter: lg.partialVisibilityGutter,
      slidesToSlide: lg.slidesToSlide,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: md.horizontalItems,
      slidesToSlide: md.slidesToSlide,
      partialVisibilityGutter: md.partialVisibilityGutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: sm.horizontalItems,
      slidesToSlide: sm.slidesToSlide,
      partialVisibilityGutter: sm.partialVisibilityGutter,
    },
  }

  useLayoutEffect(() => {
    function formatData () {
      let counter = 0
      let group = 0
      const groups = []
      for (let i = 0; i < children.length; i++) {
        if (counter < breakpoint.verticalItems) {
          if (groups[group]) {
            groups[group].push(children[i])
          } else {
            groups.push([ children[i] ])
          }
        } else {
          counter = 0
          group++
          groups.push([ children[i] ])
        }
        counter++
      }
      setItemSlider(groups)
    }

    formatData()
  }, [ breakpoint ])

  useLayoutEffect(() => {
    if (windowWidth >= WIDTH_XL) {
      setBreakpoint(xl)
    } else if (windowWidth >= WIDTH_LG && windowWidth < WIDTH_XL) {
      setBreakpoint(lg)
    } else if (windowWidth >= WIDTH_MD && windowWidth < WIDTH_LG) {
      setBreakpoint(md)
    } else {
      setBreakpoint(sm)
    }

    CarouselRef.current.goToSlide(0)
  }, [ windowWidth ])

  return (
    <OverrideCss>
      <Carousel
        ref={CarouselRef as RefObject<Carousel>}
        containerClass={containerClass}
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        focusOnSelect={false} 
        infinite={false}
        keyBoardControl
        minimumTouchDrag={80}
        partialVisible={true}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        customDot={CustomDots ? <CustomDots /> : null}
        showDots={itemSlider.length > breakpoint.horizontalItems}
        slidesToSlide={1}
        swipeable
        removeArrowOnDeviceType={removeArrowOnDeviceType}
      >
        {
          itemSlider.map((group: [], index: number) => (
            <div key={index}>
              { group.map((item: ReactNode) => item) }
            </div>
          ))
        }
      </Carousel>
    </OverrideCss>
  )
}

VerticalCarousel.defaultProps = {
  sm: {
    horizontalItems: 1,
    verticalItems: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  md: {
    horizontalItems: 1,
    verticalItems: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  lg: {
    horizontalItems: 1,
    verticalItems: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  xl: {
    horizontalItems: 1,
    verticalItems: 4,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
}

export default VerticalCarousel
