import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const CreditOnTime = styled.section`
  button {
    text-transform: none;
    @media ${device.mobile} {
      width: 100%;
    }
    @media ${device.tablet} {
      min-width: 100%;
      margin: 0 auto;
    }
  }

  a {
    text-transform: none;
  }
`
